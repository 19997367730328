<!-- Banner Section -->
<section class="page-banner">
    <div class="image-layer" style="background-image:url(images/background/image-7.jpg);"></div>
    <div class="shape-1"></div>
    <div class="shape-2"></div>
    <div class="banner-inner">
        <div class="auto-container">
            <div class="inner-container clearfix">
                <h1>Wait for something new!!!</h1>
                <div class="page-nav">
                </div>
            </div>
        </div>
    </div>
</section>
<!--End Banner Section -->
<!--Error Section-->
<section class="error-section">
    <div class="auto-container">
        <div class="content">
            <div class="big-text">
                <img src="images/icons/404-image.png" id="error-404" class="img-fluid" alt="">
            </div>
            <h2>Sorry We're under construction!</h2>
            <div class="text">The page you are looking is being upgraded beyond your imagination.</div>
            <div class="link-box">
                <a class="theme-btn btn-style-one" href="javascript:void(0);">
                    <i class="btn-curve"></i>
                    <span class="btn-title" routerLink = "">Back to home</span>
                </a>
            </div>
        </div>
    </div>
</section>