<!-- Main Header -->
<header class="main-header header-style-one">

    <!-- Header Upper -->
    <div class="header-upper">
        <div class="inner-container clearfix">
            <!--Logo-->
            <div class="logo-box">
                <div class="logo"><a routerLink="" href="javascript:void(0);" title="Massbunk - Smart Web Design Agency"><img
                            src="{{images.logo}}" id="thm-logo" alt="Massbunk - Smart Web Design Agency"
                            title="Massbunk - Digitize Your Dream"></a></div>
            </div>
            <div class="nav-outer clearfix">
                <!--Mobile Navigation Toggler-->
                <div class="mobile-nav-toggler"><span class="icon flaticon-menu-2"></span><span
                        class="txt">Menu</span></div>

                <!-- Main Menu -->
                <nav class="main-menu navbar-expand-md navbar-light">
                    <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                        <ul class="navigation clearfix">
                            <li routerLinkActive="current" [routerLinkActiveOptions]="{exact: true}"><a routerLink = '' skipLocationChange href="javascript:void(0);">Home</a></li>
                            <!-- <li routerLinkActive="current"><a routerLink= "/about" skipLocationChange href="javascript:void(0);">About Us</a></li> -->
                            <li routerLinkActive="current"><a routerLink="/services" skipLocationChange href="javaScript:void(0);">Services</a>
                            </li>
                            <li routerLinkActive="current"><a routerLink="/contact" skipLocationChange href="javaScript:void(0);">Contact</a></li>
                        </ul>
                    </div>
                </nav>
            </div>

            <div class="other-links clearfix">
                <div class="link-box">
                    <div class="call-us">
                        <a class="link" routerLink= "/contact" skipLocationChange href="javascript:void(0)" >
                            <span class="icon"></span>
                           <!--  <span class="sub-text">Call Anytime</span>
                            <span class="number">9094532303</span> -->
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!--End Header Upper-->
</header>
<div class="side-menu__block">


    <div class="side-menu__block-overlay custom-cursor__overlay">
        <div class="cursor"></div>
        <div class="cursor-follower"></div>
    </div><!-- /.side-menu__block-overlay -->
    <div class="side-menu__block-inner ">
        <div class="side-menu__top justify-content-end">

            <a href="#" class="side-menu__toggler side-menu__close-btn"><img src="https://product.massbunk.in/massbunk/close-1-1.png"
                    alt=""></a>
        </div><!-- /.side-menu__top -->


        <nav class="mobile-nav__container">
            <!-- content is loading via js -->
        </nav>
        <div class="side-menu__sep"></div><!-- /.side-menu__sep -->
        <div class="side-menu__content">
            <p>Digitize Your Dreams</p>
            <p><a href="mailto:info@massbunk.in">info@massbunk.in</a> <br> <!-- <a href="tel:888-999-0000">888
                    999 0000</a> --></p>
            <div class="side-menu__social">
                <a href="https://www.facebook.com/MassBunkOffl/"><i class="fab fa-facebook-square"></i></a>
                <a href="https://twitter.com/mass_bunk"><i class="fab fa-twitter"></i></a>
                <a href="https://www.instagram.com/massbunkoffl/"><i class="fab fa-instagram"></i></a>
            </div>
        </div><!-- /.side-menu__content -->
    </div><!-- /.side-menu__block-inner -->
</div><!-- /.side-menu__block -->

<!--Search Popup-->
<div class="search-popup">
    <div class="search-popup__overlay custom-cursor__overlay">
        <div class="cursor"></div>
        <div class="cursor-follower"></div>
    </div><!-- /.search-popup__overlay -->
    <div class="search-popup__inner">
        <form action="#" class="search-popup__form">
            <input type="text" name="search" placeholder="Type here to Search....">
            <button type="submit"><i class="fa fa-search"></i></button>
        </form>
    </div><!-- /.search-popup__inner -->
</div><!-- /.search-popup -->