<!-- Banner Section -->
<section class="page-banner">
    <div class="image-layer" style="background-image:url({{content.img}});"></div>
    <div class="shape-1"></div>
    <div class="shape-2"></div>
    <div class="banner-inner">
        <div class="auto-container">
            <div class="inner-container clearfix">
                <h1>About</h1>
                <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                        <li><a routerLink="" skipLocationChange href="javascript:void(0)">Home</a></li>
                        <li class="active">About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--End Banner Section -->
<!--Discover Section-->
<section class="discover-section">
    <div class="auto-container">
        <div class="row clearfix">
            <!--Discover Block-->
            <div class="discover-block col-lg-6 col-md-12 col-sm-12">
                <div class="inner-box">
                    <div class="image-box"><img src="{{aboutSection[0].img}}" alt=""></div>
                    <div class="cap-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="cap-inner">
                            <h5>Digitize Your Dreams..!!</h5>
                            <div class="more-link"><a routerLink = '/about' skipLocationChange href="javascript:void(0);"><span
                                        class="fa fa-angle-right"></span></a></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Discover Block-->
            <div class="discover-block col-lg-6 col-md-12 col-sm-12">
                <div class="inner-box">
                    <div class="image-box"><img src="{{aboutSection[1].img}}" alt=""></div>
                    <div class="cap-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div class="cap-inner">
                            <h5>The best digital agency you’ll ever need</h5>
                            <div class="more-link"><a class="theme-btn" href="about.html"><span
                                        class="fa fa-angle-right"></span></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Testimonials Section-->
<section class="testimonials-section">
    <div class="auto-container">
        <div class="sec-title">
            <h2>Customer feedbacks <span class="dot">.</span></h2>
        </div>
        <div class="carousel-box">
            <div class="testimonials-carousel owl-theme owl-carousel">
                <div *ngFor="let content of feedBack" class="testi-block">
                    <div class="inner">
                        <div class="icon"><span>“</span></div>
                        <div class="info">
                            <div class="image"><a href="{{content.url}}"><img src="{{content.img}}"
                                        alt=""></a></div>
                            <div class="name">{{content.name}}</div>
                            <div class="designation">{{content.designation}}</div>
                        </div>
                        <div class="text">{{content.content}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Sponsors Section-->
<section class="sponsors-section-two">
    <div class="auto-container">
        <!--Sponsors Carousel-->
        <div class="row clearfix">
            <div class="title-col col-xl-5 col-lg-12 col-md-12">
                <div class="sec-title">
                    <h2>Some of the clients we have worked with <span class="dot">.</span></h2>
                </div>
            </div>

            <div class="logo-col col-xl-7 col-lg-12 col-md-12">
                <div class="row clearfix">
                    <div *ngFor = "let client of clients"class="logo-block col-xl-4 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <div class="image-box"><a href="{{client.url}}"><img src="{{client.img}}" alt=""></a></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>