<!-- Banner Section -->
<section class="banner-section banner-one">
  <div class="left-based-text">
    <div class="base-inner">
      <div class="hours">
        <ul class="clearfix">
          <li><span>mon - fri</span></li>
          <li><span>9am - 7pm</span></li>
        </ul>
      </div>
      <div class="social-links">
        <ul class="clearfix">
          <li>
            <a href="https://twitter.com/mass_bunk"><span>Twitter</span></a>
          </li>
          <li>
            <a href="https://www.facebook.com/MassBunkOffl/"
              ><span>Facebook</span></a
            >
          </li>
          <li>
            <a href="https://www.instagram.com/massbunkoffl/"
              ><span>Instagram</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="banner-carousel owl-theme owl-carousel">
    <!-- Slide Item -->
    <div class="slide-item" *ngFor="let slide of content">
      <div
        class="image-layer"
        style="background-image: url({{ slide.img }});"
      ></div>
      <div class="left-top-line"></div>
      <div class="right-bottom-curve"></div>
      <div class="right-top-curve"></div>
      <div class="auto-container">
        <div class="content-box">
          <div class="content">
            <div class="inner">
              <div class="sub-title">Welcome to MassBunk</div>
              <h1>{{ slide.title }}</h1>
              <!-- <div class="link-box">
                                <a class="theme-btn btn-style-one" href="about.html">
                                    <i class="btn-curve"></i>
                                    <span class="btn-title">Discover More</span>
                                </a>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--End Banner Section -->
<!--Services Section-->
<section class="services-section">
  <div class="auto-container">
    <div class="row clearfix">
      <!--Title Block-->
      <div class="title-block col-xl-6 col-lg-12 col-md-12 col-sm-12">
        <div class="inner">
          <div class="sec-title">
            <h2>
              We Digitize <br />Your Dreams<span class="dot">.</span
              ><span class="dot">.</span><span class="dot">.</span>
            </h2>
            <div class="lower-text">
              MassBunk is the amphitheater of opportunities for anyone with a
              zeal to nurture their dreams into realities digitally.
            </div>
          </div>
        </div>
      </div>
      <!--Service Block-->
      <div
        class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      >
        <div class="inner-box">
          <div class="bottom-curve"></div>
          <div class="icon-box"><span class="flaticon-responsive"></span></div>
          <h6>
            <a>Website <br />Development</a>
          </h6>
        </div>
      </div>
      <!--Service Block-->
      <div
        class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div class="inner-box">
          <div class="bottom-curve"></div>
          <div class="icon-box"><span class="flaticon-computer"></span></div>
          <h6>
            <a>Brand<br />Identity</a>
          </h6>
        </div>
      </div>
      <!--Service Block-->
      <div
        class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      >
        <div class="inner-box">
          <div class="bottom-curve"></div>
          <div class="icon-box">
            <span class="flaticon-digital-marketing"></span>
          </div>
          <h6>
            <a>digital <br />marketing</a>
          </h6>
        </div>
      </div>
      <!--Service Block-->
      <div
        class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
        data-wow-delay="300ms"
        data-wow-duration="1500ms"
      >
        <div class="inner-box">
          <div class="bottom-curve"></div>
          <div class="icon-box"><span class="flaticon-development"></span></div>
          <h6>
            <a>seo & content <br />writing</a>
          </h6>
        </div>
      </div>
      <!--Service Block-->
      <div
        class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
        data-wow-delay="600ms"
        data-wow-duration="1500ms"
      >
        <div class="inner-box">
          <div class="bottom-curve"></div>
          <div class="icon-box">
            <span class="flaticon-app-development"></span>
          </div>
          <h6>
            <a>App <br />Development</a>
          </h6>
        </div>
      </div>
      <!--Service Block-->
      <div
        class="service-block col-xl-3 col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
        data-wow-delay="900ms"
        data-wow-duration="1500ms"
      >
        <div class="inner-box">
          <div class="bottom-curve"></div>
          <div class="icon-box"><span class="flaticon-ui"></span></div>
          <h6>
            <a>Ui/UX <br />designing</a>
          </h6>
        </div>
      </div>
    </div>
  </div>
</section>
<!--About Section-->
<section class="about-section">
  <div class="auto-container">
    <div class="row clearfix">
      <!--Image Column-->
      <div class="image-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
        <div class="inner">
          <div
            class="image-block wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <img src="{{ aboutSection[1].img }}" alt="" />
          </div>
          <div
            class="image-block wow fadeInUp"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <img src="{{ aboutSection[1].img }}" alt="" />
          </div>
        </div>
      </div>
      <!--Text Column-->
      <div class="text-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
        <div class="inner">
          <div class="sec-title">
            <h2>
              The backbenchers' fort of opportunity <span class="dot">.</span>
            </h2>
            <div class="lower-text">
              We help our customers embrace digital transformation effortlessly
              by dissolving their challenges every step of the way.
            </div>
          </div>
          <div class="text">
            <p>
              A creativity- driven full service digital agency that offers every
              service from Branding, Websites, Digital Strategy & Execution to
              Content Marketing, Campaign Management , and Insights & Analytics.
            </p>
          </div>
          <div class="text clearfix">
            <ul>
              <li>Sell - Grow sales</li>
              <li>Speak - Get closer to customers</li>
              <li>Serve - Add value to Your Brand</li>
              <li>Sizzle - Extending your brand online</li>
            </ul>
            <div class="since">
              <span class="txt">Since <br />2018</span>
            </div>
          </div>
          <div class="link-box">
            <a
              class="theme-btn btn-style-one"
              skipLocationChange
              href="javascript:void(0)"
            >
              <i class="btn-curve"></i>
              <span class="btn-title">Discover More</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--We DO Section-->
<section class="we-do-section">
  <div class="auto-container">
    <div class="row clearfix">
      <!--Left Column-->
      <div class="left-col col-lg-6 col-md-12 col-sm-12">
        <div class="inner">
          <div class="sec-title">
            <h2>
              We do more then our <br />competitions <span class="dot">.</span>
            </h2>
          </div>
          <div class="featured-block clearfix">
            <div class="image">
              <img
                src="https://product.massbunk.in/massbunk/featured-image-4.jpg"
                alt=""
              />
            </div>
            <div class="text">
              There are many variatns of passages the majority have suffered
              alteration in some foor randomised words believable.
            </div>
          </div>
          <div class="progress-box">
            <div class="bar-title">Digital marketing</div>
            <div class="bar">
              <div class="bar-inner count-bar" data-percent="70%">
                <div class="count-box">
                  <span class="count-text" data-stop="70" data-speed="1500"
                    >0</span
                  >%
                </div>
              </div>
            </div>
          </div>
          <div class="progress-box">
            <div class="bar-title">Brand Identity</div>
            <div class="bar">
              <div class="bar-inner count-bar" data-percent="85%">
                <div class="count-box">
                  <span class="count-text" data-stop="85" data-speed="1500"
                    >0</span
                  >%
                </div>
              </div>
            </div>
          </div>
          <div class="progress-box">
            <div class="bar-title">Web / App Development</div>
            <div class="bar">
              <div class="bar-inner count-bar" data-percent="75%">
                <div class="count-box">
                  <span class="count-text" data-stop="75" data-speed="1500"
                    >0</span
                  >%
                </div>
              </div>
            </div>
          </div>
          <div class="progress-box">
            <div class="bar-title">Search Engine Optimization</div>
            <div class="bar">
              <div class="bar-inner count-bar" data-percent="70%">
                <div class="count-box">
                  <span class="count-text" data-stop="70" data-speed="1500"
                    >0</span
                  >%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Right Column-->
      <div class="right-col col-lg-6 col-md-12 col-sm-12">
        <div class="inner">
          <div class="faq-box">
            <ul class="accordion-box clearfix">
              <!--Block-->
              <li class="accordion block active-block">
                <div class="acc-btn active">
                  <span class="count"></span> Brand Identity
                </div>
                <div class="acc-content current">
                  <div class="content">
                    <div class="text">
                      With a data driven and user centered approach, we build
                      and activate brands and help those who already exist to
                      clarify who they are and what they stand for.
                    </div>
                  </div>
                </div>
              </li>

              <!--Block-->
              <li class="accordion block">
                <div class="acc-btn">
                  <span class="count"></span> Web & App Development
                </div>
                <div class="acc-content">
                  <div class="content">
                    <div class="text">
                      Our purpose is to create impactful marketing websites,
                      optimized to drive traffic, engagement and conversion for
                      businesses across various industries.
                    </div>
                  </div>
                </div>
              </li>

              <!--Block-->
              <li class="accordion block">
                <div class="acc-btn">
                  <span class="count"></span> Digital Marketing
                </div>
                <div class="acc-content">
                  <div class="content">
                    <div class="text">
                      Our agency’s content marketing services will help you
                      increase website traffic from your online marketing
                      channels like social media, search engines, or email
                      marketing.
                    </div>
                  </div>
                </div>
              </li>
              <!--Block-->
              <li class="accordion block">
                <div class="acc-btn">
                  <span class="count"></span> Search Engine Optimization
                </div>
                <div class="acc-content">
                  <div class="content">
                    <div class="text">
                      Letting data help drive decisions is key to not being
                      blinded by opinion. We’re experts on Google Analytics,
                      reading social media data and other tools while
                      supplementing hard data with industry trends.
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Gallery Section -->
<section class="gallery-section">
  <div class="auto-container">
    <!--MixitUp Galery-->
    <div class="mixitup-gallery">
      <div class="upper-row clearfix">
        <div class="sec-title">
          <h2>work showcase <span class="dot">.</span></h2>
        </div>
        <!--Filter-->
        <div class="filters clearfix">
          <ul class="filter-tabs filter-btns clearfix">
            <li class="active filter" data-role="button" data-filter="all">
              All<sup>[11]</sup>
            </li>
            <li class="filter" data-role="button" data-filter=".branding">
              Branding<sup>[3]</sup>
            </li>
            <li
              class="filter"
              data-role="button"
              data-filter=".app-development"
            >
              App Development<sup>[2]</sup>
            </li>
            <li
              class="filter"
              data-role="button"
              data-filter=".digital-marketing"
            >
              Digital Marketing<sup>[5]</sup>
            </li>
            <li class="filter" data-role="button" data-filter=".web-design">
              Web Design<sup>[1]</sup>
            </li>
          </ul>
        </div>
      </div>
      <div class="filter-list row">
        <!-- Gallery Item -->
        <div
          *ngFor="let content of workCase"
          class="gallery-item mix all {{
            content.category
          }} col-lg-4 col-md-6 col-sm-12"
        >
          <div class="inner-box">
            <figure class="image">
              <img src="{{ content.img }}" alt="" />
            </figure>
            <a
              href="{{ content.img }}"
              class="lightbox-image overlay-box"
              data-fancybox="gallery"
            ></a>
            <div class="cap-box">
              <div class="cap-inner">
                <div class="cat">
                  <span>{{ content.companyName }}</span>
                </div>
                <div class="title">
                  <h5>
                    <a href="{{ content.url }}">{{ content.category }}</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Funfacts Section -->
<!-- End Funfacts Section -->
<!-- Trusted Section -->
<!-- <section class="trusted-section">
    <div class="auto-container">
        <div class="outer-container">
            <div class="row clearfix">
                <div class="left-col col-xl-5 col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                        <div class="col-header">
                            <div class="header-inner">
                                <span>We’re Committed To Deliver High Quality Projects .</span>
                            </div>
                        </div>
                        <div class="features">
                            <div class="feature">
                                <div class="count"><span>01</span></div>
                                <h5>TOTAL DESIGN FREEDOM FOR EVERYONE</h5>
                                <div class="sub-text">core features</div>
                            </div>
                            <div class="feature">
                                <div class="count"><span>02</span></div>
                                <h5>BASIC RULES OF RUNNING WEB AGENCY</h5>
                                <div class="sub-text">core features</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-col col-xl-7 col-lg-6 col-md-12 col-sm-12">
                    <div class="inner">
                        <div class="sec-title">
                            <h2>We’re trusted by more <br>than 6260 clients<span class="dot">.</span></h2>
                            <div class="lower-text">There are many variations of passages of Lorem Ipsum
                                available, but the majority have suffered alteration in some form, simply free
                                text by injected humour, or randomised.</div>
                        </div>
                        <div class="featured-block-two clearfix">
                            <div class="image"><img src="http://layerdrops.com/linoorhtml/images/resource/featured-image-5.jpg" alt=""></div>
                            <div class="text">
                                <ul>
                                    <li> Suspe ndisse sagittis leo.</li>
                                    <li>Entum estibulum is posuere.</li>
                                    <li>If you are going to use passage.</li>
                                    <li>Lorem Ipsum on the tend to repeat.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section> -->
<!-- Parallax Section -->
<section class="parallax-section">
  <div
    class="image-layer"
    style="background-image: url(https://product.massbunk.in/massbunk/Team.jpg)"
  ></div>
  <div class="auto-container">
    <div class="content-box">
      <div class="icon-box"><span class="flaticon-team"></span></div>
      <h2>
        Greatness in business is always acheived <span>through team work.</span>
      </h2>
    </div>
  </div>
</section>
<!-- Call To Section -->
<section class="call-to-section">
  <div class="auto-container">
    <div class="inner clearfix">
      <div
        class="shape-1 wow slideInRight"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      ></div>
      <div
        class="shape-2 wow fadeInDown"
        data-wow-delay="0ms"
        data-wow-duration="1500ms"
      ></div>
      <h2>Let's Get Your Project <br />Started!</h2>
      <div class="link-box">
        <a
          class="theme-btn btn-style-two"
          routerLink="/contact"
          skipLocationChange
          href="javascript:;"
        >
          <i class="btn-curve"></i>
          <span class="btn-title">Contact us</span>
        </a>
      </div>
    </div>
  </div>
</section>
