<!-- Banner Section -->
<section class="page-banner">
    <div class="image-layer" style="background-image:url({{content.img}});"></div>
    <div class="shape-1"></div>
    <div class="shape-2"></div>
    <div class="banner-inner">
        <div class="auto-container">
            <div class="inner-container clearfix">
                <h1>Services</h1>
                <!-- <div class="page-nav">
                    <ul class="bread-crumb clearfix">
                        <li><a routerLink="" skipLocationChange href="javascript:void(0)">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!--End Banner Section -->
<!--Services Section-->
<section class="services-section-three padd-top">
    <div class="auto-container">
        <div class="services">
            <div class="row clearfix">
                <!--Service Block-->
                <div class="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="0ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="bottom-curve"></div>
                        <div class="icon-box"><span class="flaticon-computer"></span></div>
                        <h5><a href="#">UX <br>Desiging</a></h5>
                        <ul>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">UX Research</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Information Architecture</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Mood Boards</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Wire Framing</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Interaction Design</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">UX Testing</div></li>
                            <li><div class="link-box"><a href="javascript:void(0)"><span class="fa fa-angle-right"></span></a></div></li>
                        </ul>
                    </div>
                </div>

                <!--Service Block-->
                <div class="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="300ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="bottom-curve"></div>
                        <div class="icon-box"><span class="flaticon-digital"></span></div>
                        <h5><a href="#">Digital <br>Marketing</a></h5>
                        <ul>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Inbound Marketing</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Brand Monitoring</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Social Media Marketing</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Search Engine Marketing (SEM)</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Email Marketing</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Analytics Consultation</div></li>
                            <li><div class="link-box"><a href="javascript:void(0)"><span class="fa fa-angle-right"></span></a></div></li>
                        </ul>
                    </div>
                </div>

                <!--Service Block-->
                <div class="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="600ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="bottom-curve"></div>
                        <div class="icon-box"><span class="flaticon-responsive"></span></div>
                        <h5><a href="#">Website <br>Development</a></h5>
                        <ul>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Web Application Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">CMS Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Ecommerce Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Node Js Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Angular Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">React Development</div></li>
                            <li><div class="link-box"><a href="javascript:void(0)"><span class="fa fa-angle-right"></span></a></div></li>
                        </ul>
                    </div>
                </div>

                <!--Service Block-->
                <div class="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="900ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="bottom-curve"></div>
                        <div class="icon-box"><span class="flaticon-development"></span></div>
                        <h5><a href="#">Seo & Content<br>Writing</a></h5>
                        <ul>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Enerprise SEO</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Ecommerce SEO</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Local SEO</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Google Recovery Services</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">SEO Reseller Service</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">SEO Copywriting</div></li>
                            <li><div class="link-box"><a href="javascript:void(0)"><span class="fa fa-angle-right"></span></a></div></li>
                        </ul>
                    </div>
                </div>

                <!--Service Block-->
                <div class="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="1200ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="bottom-curve"></div>
                        <div class="icon-box"><span class="flaticon-app-development"></span></div>
                        <h5><a href="#">App<br>Development</a></h5>
                        <ul>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Android App Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">IOS App Development </div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">React Native Apps</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Ionic App Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Xamarin App Development</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Phone Gap Apps</div></li>
                            <li><div class="link-box"><a href="javascript:void(0)"><span class="fa fa-angle-right"></span></a></div></li>
                        </ul>
                    </div>
                </div>

                <!--Service Block-->
                <div class="service-block-two col-xl-4 col-lg-6 col-md-6 col-sm-12 wow fadeInUp"
                    data-wow-delay="1500ms" data-wow-duration="1500ms">
                    <div class="inner-box">
                        <div class="bottom-curve"></div>
                        <div class="icon-box"><span class="flaticon-ui"></span></div>
                        <h5><a href="#">Brand<br>Identity</a></h5>
                        <ul>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Logo & Brand Identity</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Graphic Design</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Package Design</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Info Graphics Design</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Print Design</div></li>
                            <li><span class="fa fa-angle-right"></span><div class="text services-text">Creative Copy Writing</div></li>
                            <li><div class="link-box"><a href="javascript:void(0)"><span class="fa fa-angle-right"></span></a></div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Agency Section-->
<section class="agency-section about-section">
    <div class="auto-container">
        <div class="row clearfix">
            <!--Left Column-->
            <div class="left-col col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="inner">
                    <div class="sec-title">
                        <h2>Best design Agency <br>solutions <span class="dot">.</span></h2>
                    </div>

                    <!--Default Tabs-->
                    <div class="default-tabs tabs-box">

                        <!--Tab Btns-->
                        <ul class="tab-btns tab-buttons clearfix">
                            <li data-tab="#tab-1" class="tab-btn active-btn"><span>Our Mission</span></li>
                            <li data-tab="#tab-2" class="tab-btn"><span>Our Vision</span></li>
                            <li data-tab="#tab-3" class="tab-btn"><span>Our History</span></li>
                        </ul>

                        <!--Tabs Container-->
                        <div class="tabs-content">

                            <!--Tab-->
                            <div class="tab active-tab" id="tab-1">
                                <div class="content">
                                    <div class="text">Our mission is to create a safe and positive learning environment, encourage life-long learning practices, share usable informations, skills through collaboration to solve problems and new techniques to help individuals grow</div>
                                </div>
                            </div>

                            <!--Tab-->
                            <div class="tab" id="tab-2">
                                <div class="content">
                                    <div class="text">Our Vision is, hold the vision and trust the process. what we see ahead of us, and positive influence on how they think, act and feel. Our vision is obstructed and trained not for economic growth, but also of a growth which would improve your skills beyond your thought.</div>
                                </div>
                            </div>

                            <!--Tab-->
                            <div class="tab" id="tab-3">
                                <div class="content">
                                    <div class="text">A Pack of Back-Benchers thriving for a innovative development in this digital world. Providing the Digital experience to all the brands from every part of the world.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Right Column-->
            <div class="right-col text-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="inner">
                    <div class="text clearfix">
                        <ul>
                            <li>Sell - Grow sales</li>
                            <li>Speak - Get closer to customers</li>
                            <li>Serve - Add value to Your Brand</li>
                            <li>Sizzle - Extending your brand online</li>
                        </ul>
                        <div class="since"><span class="txt">Since <br>2018</span></div>
                    </div>
                    <div class="link-box">
                        <a class="theme-btn btn-style-one" skipLocationChange href="javascript:void(0)">
                            <i class="btn-curve"></i>
                            <span class="btn-title">Discover More</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Call To Section -->
<section class="call-to-section-two alternate">
    <div class="auto-container">
        <div class="inner clearfix">
            <h2>We’re Ready to Bring Bigger <br>& Stronger Projects</h2>
            <div class="link-box">
                <a class="theme-btn btn-style-two" routerLink="/contact" skipLocationChange href="javascript:void(0)">
                    <i class="btn-curve"></i>
                    <span class="btn-title">Contact us</span>
                </a>
            </div>
        </div>
    </div>
</section>