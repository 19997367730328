<!-- Main Footer -->
<footer class="main-footer">
    <div class="auto-container">
        <!--Widgets Section-->
        <div class="widgets-section">
            <div class="row clearfix">

                <!--Column-->
                <div class="column col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div class="footer-widget logo-widget">
                        <div class="widget-content">
                            <div class="logo">
                                <a routerLink = '' href="javascript:void(0);"><img id="fLogo" src="{{images.logo}}" alt="" /></a>
                            </div>
                            <div class="text">Digitize your Dreams<span class="dot">.</span></div>
                            <ul class="social-links clearfix">
                                <li><a href="https://www.facebook.com/MassBunkOffl/"><span class="fab fa-facebook-square"></span></a></li>
                                <li><a href="https://twitter.com/mass_bunk"><span class="fab fa-twitter"></span></a></li>
                                <li><a href="https://www.instagram.com/massbunkoffl/"><span class="fab fa-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--Column-->
                <div class="column col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div class="footer-widget links-widget">
                        <div class="widget-content">
                            <h6>Explore</h6>
                            <div class="row clearfix">
                                <div class="col-md-6 col-sm-12">
                                    <ul>
                                        <li><a routerLink="" skipLocationChange href="javascript:void(0);">Home</a></li>
                                        <li><a routerLink="/services" skipLocationChange href="javascript:void(0);">Services</a></li>
                                        <!-- <li><a routerLink="/about" skipLocationChange href="javascript:void(0);">About</a></li> -->
                                        <!-- <li><a routerLink="" skipLocationChange href="javascript:void(0);"></a></li> -->
                                        <li><a routerLink="/contact" skipLocationChange href="javascript:void(0);">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Column-->
                <div class="column col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div class="footer-widget info-widget">
                        <div class="widget-content">
                            <h6>Contact</h6>
                            <ul class="contact-info">
                                <li class="address"><span class="icon flaticon-pin-1"></span> 19,Jagadish Nagar<br>
                                    1st cross,Arul Nagar, <br>Nandhivaram,<br>
                                    Guduvancherry</li>
                                <!-- <li><span class="icon flaticon-call"></span><a href="tel:+91 9094532303">+91 9094532303</a></li> -->
                                <li><span class="icon flaticon-email-2"></span><a
                                        href="mailto:info@massbunk.in">info@massbunk.in</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!--Column-->
                <div class="column col-xl-3 col-lg-6 col-md-6 col-sm-12">
                    <div class="footer-widget newsletter-widget">
                        <div class="widget-content">
                            <h6>Newsletter</h6>
                            <div class="newsletter-form">
                                <form>
                                    <div class="form-group clearfix">
                                        <input type="email" name="email" value="" placeholder="Email Address"
                                            required="">
                                        <button type="submit" class="theme-btn"><span
                                                class="fa fa-envelope"></span></button>
                                    </div>
                                </form>
                            </div>
                            <div class="text">Sign up for our latest news & articles. We won’t give you spam
                                mails.</div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="auto-container">
            <div class="inner clearfix">
                <div class="copyright">&copy; Copyright 2021 by MassBunk</div>
            </div>
        </div>
    </div>

</footer>