<div class="page-wrapper">
    <!-- Preloader -->
    <div class="preloader">
        <div class='body'>
            <span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
            <div class='base'>
              <span></span>
              <div class='face'></div>
            </div>
          </div>
          <div class='longfazers'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
    </div>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>